<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title
        >Roles de Acceso a la Consulta {{ consulta.nombre }}</v-card-title
      >
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container v-if="!loadingSave">

          <v-row>
            <v-col cols="12" md="12">
              <div
                v-if="loadingRoles"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingRoles"
                v-model="consulta.roles_acceso"
                :items="roles"
                :item-text="'name'"
                :item-value="'name'"
                attach
                chips
                label="Roles de acceso"
                :rules="required"
                multiple
                outlined
              ></v-select>
            </v-col>
          </v-row>

        </v-container>
      </v-form>
      <v-divider></v-divider>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <!-- <pre>{{consulta}}</pre> -->

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición de roles?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import definicionConsulta from "@/services/definicionConsulta.service";
import RoleService from "@/services/role.service";
import { mapState } from "vuex";

export default {
  data() {
    return {
      consulta: {},
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      roles: [],
      loadingRoles: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {
    
  },
  mounted() {
    if (!this.permissions.includes("Autogestor - Definición de consulta"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_autogestor_preconsulta")) {
      this.consulta = JSON.parse(
        localStorage.getItem("datos_autogestor_preconsulta")
      );
      this.consulta.roles_acceso = JSON.parse(this.consulta.roles_acceso)
    }
    this.fetchRoles("");
    
  },
  methods: {
    save() {
      this.loadingRoles = false
      this.consulta.roles_acceso = JSON.stringify(this.consulta.roles_acceso)

      console.log(this.consulta)

      this.loadingSave = true;
      if (this.consulta.hasOwnProperty("id")) {
        definicionConsulta.editItem(this.consulta).then(
          (response) => {
            this.$router.push({ path: "/autogestor/gestor-consultas" });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
              console.log("presentación del error:")
              console.log(error)
          }
        );
      }
    },
    fetchRoles(cadenaBusqueda) {
      this.loadingRoles = true;
      RoleService.getItems(cadenaBusqueda).then(
        (response) => {
          this.roles = response.data;
          this.loadingRoles = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCampos = false;
        }
      );
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/autogestor/gestor-consultas" });
    },
  },
};
</script>

<style>
</style>
